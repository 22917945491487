<template>
	<PopupTwoCol name="depot"
	text="<h3>Le dépôt de Vern-sur-Seiche</h3><br/><p>Le dépôt de Vern-sur-Seiche est situé à 10 km au sud de Rennes. Ce dépôt est exclusivement livré par le pipeline ou oléoduc (tuyau servant au transport à grande distance de pétrole) de 93 km, qui le relie à la raffinerie de Donges.</p><br/><div class='popup__statistics'><p class='dark__red'>Quelques chiffres</p><p>35 hectares</p><p>170 à 220 camions / jour</p><p>8 postes de distribution</p><p>165 000 m&#179; de stockage</p></div>">
	<picture slot="thumbnail__1">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/depot_de_vern_fjweb1_c_scale_w_350.jpg 350w,
		img/popup/depot_de_vern_fjweb1_c_scale_w_706.jpg 706w,
		img/popup/depot_de_vern_fjweb1_c_scale_w_970.jpg 970w"
		data-src="img/popup/depot_de_vern_fjweb1_c_scale_w_970.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
	<picture slot="thumbnail__2">
		<img class="lazy"
		sizes="(max-width: 1366px) 300px,
		(max-width: 1920px) 410px,
		970px"
		data-srcset="
		img/popup/depot_de_Vern_2_cjojwm_c_scale_w_350.jpg 350w,
		img/popup/depot_de_Vern_2_cjojwm_c_scale_w_633.jpg 633w,
		img/popup/depot_de_Vern_2_cjojwm_c_scale_w_855.jpg 855w"
		data-src="img/popup/depot_de_Vern_2_cjojwm_c_scale_w_855.jpg"
		src="static/logo-total.jpg"
		alt="">
	</picture>
</PopupTwoCol>
</template>

<script>
	export default {
		components: {
			PopupTwoCol: () => import('@/components/PopupTwoCol.vue'),
		},
	}
</script>
